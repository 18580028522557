//import * as fb from "@/firebase";
// import { auth } from "../../firebase";
// import router from "../router/index";
// import { db } from "@/firebase";
// import { IMPORT_ACTIONS } from "../action-types";
// import { IMPORT_MUTATIONS } from "../mutation-types";
export default {
  namespaced: true,

  state: {
    // Data
  },

  mutations: {
    // [MUTATION_NAME](state, payload) {
    //   state.var = payload;
    // },
  },

  actions: {
    // [ACTION_NAME]({ commit }, payload) {
    //   ... SOME MAGIC HAPPENS HERE ...
    //   commit(MUTATION_NAME, withSomeData);
    // },
  },

  getters: {},
};
