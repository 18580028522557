export const dutyManagement = {
  path: "duty-management",
  name: "duty-management",
  component: () =>
    import(
      /* webpackChunkName: "duty-management" */ "../../views/duty-management/DutyManagementIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // duty-management > reports
    {
      path: "reports",
      name: "duty-management-reports",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports" */ "../../views/duty-management/DutyManagementReports.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "reports/new",
      name: "duty-management-reports-new",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports-manage" */ "../../views/duty-management/reports/ReportNew.vue"
        ),
      meta: {
        layout: "content-without-drawer",
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "reports/edit",
      name: "duty-management-reports-edit",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports-manage" */ "../../views/duty-management/reports/ReportEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "reports/:itemId",
      name: "duty-management-reports-details",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-reports-manage" */ "../../views/duty-management/reports/ReportDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    // duty-management > archive
    {
      path: "archive",
      name: "duty-management-archive",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-archive" */ "../../views/duty-management/DutyManagementArchive.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    // duty-management > config
    {
      path: "config",
      name: "duty-management-config",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/DutyManagementConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
      children: [
        {
          path: "report-types",
          name: "duty-management-config-tab-report-types",
          component: () =>
            import(
              /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/ConfigTabReportTypes.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            // permissionID: `${duty-management_CONFIG_EVENT_TYPES_read}`,
          },
        },
      ],
    },
    {
      path: "config/report-types/new",
      name: "duty-management-config-tab-report-types-report-type-new",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/report-types/ReportTypeNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${X}`,
      },
    },
    {
      path: "config/report-types/:itemId",
      name: "duty-management-config-tab-report-types-report-type-details",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/report-types/ReportTypeDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${X}`,
      },
    },
    {
      path: "config/report-types/:itemId/edit",
      name: "duty-management-config-tab-report-types-report-type-edit",
      component: () =>
        import(
          /* webpackChunkName: "duty-management-config" */ "../../views/duty-management/config/report-types/ReportTypeEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${X}`,
      },
    },
  ],
};
