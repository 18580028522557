import * as fb from "@/firebase";
// import { auth } from "../../firebase";
// import { db } from "@/firebase";
import {
  GET_REPORTS,
  GET_ARCHIVED_REPORTS,
  // CREATE_REPORT,
  // UPDATE_REPORT,
  GET_REPORT_TYPES,
  CREATE_REPORT_TYPE,
  CREATE_REPORT_TYPES,
  UPDATE_REPORT_TYPE,
  DELETE_REPORT_TYPE,
} from "../action-types";
import {
  SET_REPORTS,
  SET_ARCHIVED_REPORTS,
  SET_REPORT_TYPES,
  SET_LOADING,
} from "../mutation-types";
export default {
  namespaced: true,

  state: {
    reports: [],
    archivedReports: [],
    reportTypes: [],
  },

  mutations: {
    [SET_REPORTS](state, payload) {
      state.reports = payload;
    },
    [SET_ARCHIVED_REPORTS](state, payload) {
      state.archivedReports = payload;
    },
    [SET_REPORT_TYPES](state, payload) {
      state.reportTypes = payload;
    },
  },

  actions: {
    [GET_REPORTS]({ commit }, payload) {
      const reports = [];
      // var options = {
      //   weekday: "short",
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      //   hour: "2-digit",
      //   minute: "2-digit",
      // };
      var limit = 60;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      var baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyReports")
        .where("status", "!=", "closed");
      var reference = baseReference;
      // if (payload.query.onlyOwn) {
      // TODO

      //   var ownUserGroup = payload.query.ownUserGroup; //rootState.organization.membership.group.id;
      //   var ownUid = fb.auth.currentUser.uid;

      //   reference = baseReference.where(
      //     "assignnedTo.uid",
      //     "contains", // TODO
      //     "ownUid"
      //   );
      // }

      return (
        reference
          // .orderBy("duration.start.timestamp", "asc")
          .limit(limit)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const object = {
                ...doc.data(),
                // Overwrite
              };
              reports.push(object);
            });
            return commit(SET_REPORTS, reports);
          })
          .catch((error) => console.log(error))
      );
    },
    [GET_ARCHIVED_REPORTS]({ commit }, payload) {
      const archivedReports = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var limit = 300;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyReports")
        .where("status", "in", payload.query.status)
        // .where("type.id", "in", payload.query.types)
        // .where(
        //   "assignedTo.uid",
        //   "==",
        //   payload.query.assignedTo.uid
        // )
        .where(
          "duration.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(payload.query.range.start))
        )
        .where(
          "duration.start.timestamp",
          "<=",
          fb.Timestamp.fromDate(new Date(payload.query.range.end))
        )
        .orderBy("duration.start.timestamp", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // overwrite
              duration: {
                start: {
                  firebaseFormat: doc.data().duration.start.timestamp,
                  dateFormat: doc.data().duration.start.timestamp.toDate(),
                  timestamp: doc
                    .data()
                    .duration.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.start.isOpen,
                },
                end: {
                  firebaseFormat: doc.data().duration.end.timestamp,
                  timestamp: doc
                    .data()
                    .duration.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.end.isOpen,
                },
              },
            };
            archivedReports.push(object);

            return commit(SET_ARCHIVED_REPORTS, archivedReports);
          });
        })
        .catch((error) => console.log(error));
    },

    [GET_REPORT_TYPES]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = [];
        fb.organizationCollection
          .doc(payload.organizationId)
          .collection("reportTypes")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              data.push(doc.data());
            });
            data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
            commit(SET_REPORT_TYPES, data);
            resolve(querySnapshot);
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              console.log("Error getting document:", error);
              reject(error);
            }
          );
      });
    },

    [CREATE_REPORT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("reportTypes")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          color: payload.color,
          sortKey: payload.sortKey,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [CREATE_REPORT_TYPES]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("reportTypes");

      payload.reportTypes.forEach(function (reportType) {
        const newDocRef = batchRef.doc();
        const object = {
          ...reportType,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_REPORT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("reportTypes")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          color: payload.color,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [DELETE_REPORT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("reportTypes")
        .doc(payload.itemId);
      docRef.delete().catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
  },

  getters: {},
};
