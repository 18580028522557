import {
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_read,
  // MEMBER_MANAGEMENT_PERSONNEL_RECORDS_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OTHER_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHANGELOG_read,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update,
  MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OTHER_update,
  MEMBER_MANAGEMENT_DEADLINES_read,
  MEMBER_MANAGEMENT_DEADLINES_create,
  // MEMBER_MANAGEMENT_DEADLINES_read,
  MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_read,
  MEMBER_MANAGEMENT_DEADLINES_update,
  MEMBER_MANAGEMENT_ASSIGNMENTS_read,
  MEMBER_MANAGEMENT_ASSIGNMENTS_create,
  // MEMBER_MANAGEMENT_ASSIGNMENTS_read,
  MEMBER_MANAGEMENT_ASSIGNMENTS_update,
  MEMBER_MANAGEMENT_SKILLS_read,
  MEMBER_MANAGEMENT_SKILLS_create,
  // MEMBER_MANAGEMENT_SKILLS_read,
  MEMBER_MANAGEMENT_SKILLS_update,
  MEMBER_MANAGEMENT_COURSES_create,
  MEMBER_MANAGEMENT_COURSES_update,
  MEMBER_MANAGEMENT_RANKS_read,
  MEMBER_MANAGEMENT_RANKS_create,
  // MEMBER_MANAGEMENT_RANKS_read,
  MEMBER_MANAGEMENT_RANKS_update,
  MEMBER_MANAGEMENT_DUTY_POSITIONS_read,
  MEMBER_MANAGEMENT_DUTY_POSITIONS_create,
  MEMBER_MANAGEMENT_DUTY_POSITIONS_update,
  MEMBER_MANAGEMENT_HONORS_read,
  MEMBER_MANAGEMENT_HONORS_create,
  MEMBER_MANAGEMENT_HONORS_update,
  MEMBER_MANAGEMENT_CUSTOM_FIELDS_create,
  MEMBER_MANAGEMENT_CUSTOM_FIELDS_update,
} from "@/data/permission-types.js";

export const memberManagement = {
  path: "member-management",
  name: "member-management",
  component: () =>
    import(
      /* webpackChunkName: "member-management" */ "../../views/member-management/MemberManagementIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // member-management > personnel-records
    {
      path: "personnel-records",
      name: "member-management-personnel-records",
      component: () =>
        import(
          /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/MemberManagementPersonnelRecords.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_read}`,
      },
    },
    {
      path: "personnel-records/:itemId",
      name: "member-management-personnel-records-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
      children: [
        {
          path: "general",
          name: "member-management-personnel-records-details-general",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabGeneral.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_read}`,
          },
        },
        {
          path: "contact",
          name: "member-management-personnel-records-details-contact",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabContact.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_read}`,
          },
        },
        {
          path: "chronicle",
          name: "member-management-personnel-records-details-chronicle",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabChronicle.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_read}`,
          },
        },
        {
          path: "certificates",
          name: "member-management-personnel-records-details-certificates",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabCertificates.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_read}`,
          },
        },
        {
          path: "emergency-contacts",
          name: "member-management-personnel-records-details-emergency-contacts",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabEmergencyContacts.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_read}`,
          },
        },
        {
          path: "accounting",
          name: "member-management-personnel-records-details-accounting",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabAccounting.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_read}`,
          },
        },
        {
          path: "youth-group",
          name: "member-management-personnel-records-details-youth-group",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabYouthGroup.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_read}`,
          },
        },
        {
          path: "other",
          name: "member-management-personnel-records-details-other",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabOther.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OTHER_read}`,
          },
        },
        {
          path: "changelog",
          name: "member-management-personnel-records-details-changelog",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordDetailsTabChangelog.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHANGELOG_read}`,
          },
        },
      ],
    },
    {
      path: "personnel-records/:itemId/edit",
      name: "member-management-personnel-records-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update}`,
      },
      children: [
        {
          path: "general",
          name: "member-management-personnel-records-edit-general",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabGeneral.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update}`,
          },
        },
        {
          path: "contact",
          name: "member-management-personnel-records-edit-contact",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabContact.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update}`,
          },
        },
        {
          path: "chronicle",
          name: "member-management-personnel-records-edit-chronicle",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabChronicle.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update}`,
          },
        },
        {
          path: "certificates",
          name: "member-management-personnel-records-edit-certificates",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabCertificates.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update}`,
          },
        },
        {
          path: "emergency-contacts",
          name: "member-management-personnel-records-edit-emergency-contacts",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabEmergencyContacts.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update}`,
          },
        },
        {
          path: "accounting",
          name: "member-management-personnel-records-edit-accounting",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabAccounting.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update}`,
          },
        },
        {
          path: "youth-group",
          name: "member-management-personnel-records-edit-youth-group",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabYouthGroup.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update}`,
          },
        },
        {
          path: "other",
          name: "member-management-personnel-records-edit-other",
          component: () =>
            import(
              /* webpackChunkName: "member-management-personnel-records" */ "../../views/member-management/personnel-records/PersonnelRecordEditTabOther.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OTHER_update}`,
          },
        },
      ],
    },
    // member-management > deadline-monitoring
    {
      path: "deadline-monitoring",
      name: "member-management-deadline-monitoring",
      component: () =>
        import(
          /* webpackChunkName: "member-management-deadline-monitoring" */ "../../views/member-management/MemberManagementDeadlineMonitoring.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_DEADLINES_read}`,
      },
    },
    {
      path: "deadline-monitoring/deadline/new",
      name: "member-management-deadline-monitoring-deadline-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-deadline-monitoring" */ "../../views/member-management/deadline-monitoring/DeadlineNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_DEADLINES_create}`,
      },
    },
    {
      path: "deadline-monitoring/deadline/:itemId",
      name: "member-management-deadline-monitoring-deadline-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-deadline-monitoring" */ "../../views/member-management/deadline-monitoring/DeadlineDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "deadline-monitoring/deadline/:itemId/edit",
      name: "member-management-deadline-monitoring-deadline-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-deadline-monitoring" */ "../../views/member-management/deadline-monitoring/DeadlineEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_DEADLINES_update}`,
      },
    },
    // member-management > performance targets
    {
      path: "performance-targets",
      name: "member-management-performance-targets",
      component: () =>
        import(
          /* webpackChunkName: "member-management-performance-targets" */ "../../views/member-management/MemberManagementPerformanceTargets.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_read}`,
      },
    },

    // member-management > assignments
    {
      path: "assignments",
      name: "member-management-assignments",
      component: () =>
        import(
          /* webpackChunkName: "member-management-assignments" */ "../../views/member-management/MemberManagementAssignments.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_ASSIGNMENTS_read}`,
      },
    },
    {
      path: "assignments/new",
      name: "member-management-assignments-assignment-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-assignments" */ "../../views/member-management/assignments/AssignmentNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
      },
    },
    {
      path: "assignments/:itemId",
      name: "member-management-assignments-assignment-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-assignments" */ "../../views/member-management/assignments/AssignmentDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "assignments/:itemId/edit",
      name: "member-management-assignments-assignment-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-assignments" */ "../../views/member-management/assignments/AssignmentEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_ASSIGNMENTS_update}`,
      },
    },
    {
      path: "assignments-planner",
      name: "member-management-assignments-planner",
      component: () =>
        import(
          /* webpackChunkName: "member-management-assignments" */ "../../views/member-management/assignments/AssignmentPlanner.vue"
        ),
      meta: {
        // layout: "content-without-drawer",
        layout: "blank",
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_ASSIGNMENTS_update}`,
      },
    },

    // member-management > config
    {
      path: "config",
      name: "member-management-config",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config" */ "../../views/member-management/MemberManagementConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "mitgliederverwaltung.konfiguration.view_Page",
      },
      children: [
        {
          path: "skills",
          name: "member-management-config-skills",
          component: () =>
            import(
              /* webpackChunkName: "member-management-config-skills" */ "../../views/member-management/config/ConfigTabSkills.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_SKILLS_read}`,
          },
        },
        {
          path: "courses",
          name: "member-management-config-courses",
          component: () =>
            import(
              /* webpackChunkName: "member-management-config" */ "../../views/member-management/config/ConfigTabCourses.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: "",
          },
        },
        // member-management > ranks
        {
          path: "ranks",
          name: "member-management-config-ranks",
          component: () =>
            import(
              /* webpackChunkName: "member-management-config-ranks" */ "../../views/member-management/config/ConfigTabRanks.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_RANKS_read}`,
          },
        },
        {
          path: "duty-positions",
          name: "member-management-config-duty-positions",
          component: () =>
            import(
              /* webpackChunkName: "member-management-config-duty-positions" */ "../../views/member-management/config/ConfigTabDutyPositions.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_DUTY_POSITIONS_read}`,
          },
        },
        {
          path: "honors",
          name: "member-management-config-honors",
          component: () =>
            import(
              /* webpackChunkName: "member-management-config" */ "../../views/member-management/config/ConfigTabHonors.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${MEMBER_MANAGEMENT_HONORS_read}`,
          },
        },
        {
          path: "custom-fields",
          name: "member-management-config-custom-fields",
          component: () =>
            import(
              /* webpackChunkName: "member-management-config" */ "../../views/member-management/config/ConfigTabCustomFields.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: "",
          },
        },
      ],
    },

    {
      path: "config/courses/course/:itemId",
      name: "member-management-config-courses-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-courses" */ "../../views/member-management/config/courses/CourseDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/courses/course/new",
      name: "member-management-config-courses-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-courses" */ "../../views/member-management/config/courses/CourseNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_RANKS_create}`,
      },
    },
    {
      path: "config/courses/course/:itemId/edit",
      name: "member-management-config-courses-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-courses" */ "../../views/member-management/config/courses/CourseEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_RANKS_update}`,
      },
    },

    {
      path: "config/courses/group/new",
      name: "member-management-config-courses-course-group-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-courses" */ "../../views/member-management/config/courses/CourseGroupNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_COURSES_create}`,
      },
    },
    {
      path: "config/courses/group/:itemId",
      name: "member-management-config-courses-course-group-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-courses" */ "../../views/member-management/config/courses/CourseGroupDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/courses/group/:itemId/edit",
      name: "member-management-config-courses-course-group-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-courses" */ "../../views/member-management/config/courses/CourseGroupEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_COURSES_update}`,
      },
    },

    {
      path: "config/skills/skill/new",
      name: "member-management-config-skills-skill-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-skills" */ "../../views/member-management/config/skills/SkillNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_SKILLS_create}`,
      },
    },
    {
      path: "config/skills/skill/:itemId",
      name: "member-management-config-skills-skill-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-skills" */ "../../views/member-management/config/skills/SkillDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/skills/skill/:itemId/edit",
      name: "member-management-config-skills-skill-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-skills" */ "../../views/member-management/config/skills/SkillEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_SKILLS_update}`,
      },
    },
    {
      path: "config/skills/group/new",
      name: "member-management-config-skills-skill-group-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-skills" */ "../../views/member-management/config/skills/SkillGroupNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_SKILLS_create}`,
      },
    },
    {
      path: "config/skills/group/:itemId",
      name: "member-management-config-skills-skill-group-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-skills" */ "../../views/member-management/config/skills/SkillGroupDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/skills/group/:itemId/edit",
      name: "member-management-config-skills-skill-group-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-skills" */ "../../views/member-management/config/skills/SkillGroupEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_SKILLS_update}`,
      },
    },
    {
      path: "config/ranks/:itemId",
      name: "member-management-config-ranks-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-ranks" */ "../../views/member-management/config/ranks/RankDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/ranks/new",
      name: "member-management-config-ranks-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-ranks" */ "../../views/member-management/config/ranks/RankNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_RANKS_create}`,
      },
    },
    {
      path: "config/ranks/:itemId/edit",
      name: "member-management-config-ranks-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-ranks" */ "../../views/member-management/config/ranks/RankEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_RANKS_update}`,
      },
    },
    {
      path: "config/duty-positions/:itemId",
      name: "member-management-config-duty-positions-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-duty-positions" */ "../../views/member-management/config/duty-positions/DutyPositionDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/duty-positions/new",
      name: "member-management-config-duty-positions-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-duty-positions" */ "../../views/member-management/config/duty-positions/DutyPositionNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_DUTY_POSITIONS_create}`,
      },
    },
    {
      path: "config/duty-positions/:itemId/edit",
      name: "member-management-config-duty-positions-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-duty-positions" */ "../../views/member-management/config/duty-positions/DutyPositionEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_DUTY_POSITIONS_update}`,
      },
    },
    {
      path: "config/honors/:itemId",
      name: "member-management-config-honors-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-honors" */ "../../views/member-management/config/honors/HonorDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/honors/new",
      name: "member-management-config-honors-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-honors" */ "../../views/member-management/config/honors/HonorNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_HONORS_create}`,
      },
    },
    {
      path: "config/honors/:itemId/edit",
      name: "member-management-config-honors-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-honors" */ "../../views/member-management/config/honors/HonorEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_HONORS_update}`,
      },
    },
    {
      path: "config/custom-fields/:itemId",
      name: "member-management-config-custom-fields-details",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-custom-fields" */ "../../views/member-management/config/custom-fields/CustomFieldDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
    },
    {
      path: "config/custom-fields/new",
      name: "member-management-config-custom-fields-new",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-custom-fields" */ "../../views/member-management/config/custom-fields/CustomFieldNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_CUSTOM_FIELDS_create}`,
      },
    },
    {
      path: "config/custom-fields/:itemId/edit",
      name: "member-management-config-custom-fields-edit",
      component: () =>
        import(
          /* webpackChunkName: "member-management-config-custom-fields" */ "../../views/member-management/config/custom-fields/CustomFieldEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${MEMBER_MANAGEMENT_CUSTOM_FIELDS_update}`,
      },
    },
  ],
};
