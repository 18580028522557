import * as fb from "@/firebase";
// import { auth } from "../../firebase";
// import router from "../router/index";
// import { db } from "@/firebase";
import { GET_BILLING_TYPES } from "../action-types";
import { SET_BILLING_TYPES } from "../mutation-types";
export default {
  namespaced: true,

  state: {
    payrollTypes: [],
  },

  mutations: {
    [SET_BILLING_TYPES](state, payload) {
      state.payrollTypes = payload;
    },
    // [MUTATION_NAME](state, payload) {
    //   state.var = payload;
    // },
  },

  actions: {
    [GET_BILLING_TYPES]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = [];
        fb.organizationCollection
          .doc(payload.organizationId)
          .collection("payrollTypes")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              data.push(doc.data());
            });
            data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
            commit(SET_BILLING_TYPES, data);
            resolve(querySnapshot);
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              console.log("Error getting document:", error);
              reject(error);
            }
          );
      });
    },
    // [ACTION_NAME]({ commit }, payload) {
    //   ... SOME MAGIC HAPPENS HERE ...
    //   commit(MUTATION_NAME, withSomeData);
    // },
  },

  getters: {},
};
