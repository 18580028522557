import {
  SCHEDULING_EVENTS_create,
  SCHEDULING_EVENTS_read,
  SCHEDULING_EVENTS_update,
  SCHEDULING_ARCHIVE_read,
  SCHEDULING_CALENDAR_read,
  SCHEDULING_TEAMPLANNER_read,
  SCHEDULING_CONFIG_GENERAL_read,
  SCHEDULING_CONFIG_EVENT_TYPES_create,
  SCHEDULING_CONFIG_EVENT_TYPES_read,
  SCHEDULING_CONFIG_EVENT_TYPES_update,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_create,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_read,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_update,
  SCHEDULING_EVENTS_RESPONSES_write,
} from "@/data/permission-types.js";

export const scheduling = {
  path: "scheduling",
  name: "scheduling",
  component: () =>
    import(
      /* webpackChunkName: "scheduling" */ "../../views/scheduling/SchedulingIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // scheduling > events
    {
      path: "events",
      name: "scheduling-events",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-events" */ "../../views/scheduling/SchedulingEvents.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_EVENTS_read}`,
      },
    },
    {
      path: "events/new",
      name: "scheduling-event-new",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-events-manage" */ "../../views/scheduling/events/EventNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_EVENTS_create}`,
      },
    },
    {
      path: "events/new-multi",
      name: "scheduling-event-new-multi",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-events-manage" */ "../../views/scheduling/events/EventNewMulti.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_EVENTS_create}`,
      },
    },
    {
      path: "events/new-csv",
      name: "scheduling-event-new-csv",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-events-manage" */ "../../views/scheduling/events/EventNewCSV.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_EVENTS_create}`,
      },
    },
    {
      path: "events/:itemId",
      name: "scheduling-event-details",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-events" */ "../../views/scheduling/events/EventDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
      children: [
        {
          path: "general",
          name: "scheduling-event-details-tab-general",
          component: () =>
            import(
              /* webpackChunkName: "scheduling-events" */ "../../views/scheduling/events/EventDetailsTabGeneral.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${SCHEDULING_EVENTS_read}`,
          },
        },
        {
          path: "responses",
          name: "scheduling-event-details-tab-responses",
          component: () =>
            import(
              /* webpackChunkName: "scheduling-events" */ "../../views/scheduling/events/EventDetailsTabResponses.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${SCHEDULING_EVENTS_read}`,
          },
        },
      ],
    },
    {
      path: "events/:itemId/responses/manage",
      name: "scheduling-event-details-manage-responses",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-events" */ "../../views/scheduling/events/EventDetailsManageResponses.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_EVENTS_RESPONSES_write}`,
      },
    },
    {
      path: "events/:itemId/edit",
      name: "scheduling-event-edit",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-events-manage" */ "../../views/scheduling/events/EventEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_EVENTS_update}`,
      },
    },
    // scheduling > calendar
    {
      path: "calendar",
      name: "scheduling-calendar",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-calendar" */ "../../views/scheduling/SchedulingCalendar.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CALENDAR_read}`,
      },
    },
    {
      path: "calendar/feeds",
      name: "scheduling-calendar-feeds",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-calendar" */ "../../views/scheduling/calendar/CalendarFeeds.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CALENDAR_read}`,
      },
    },
    // scheduling > teamplanner
    {
      path: "teamplanner",
      name: "scheduling-teamplanner",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-teamplanner" */ "../../views/scheduling/SchedulingTeamplanner.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_TEAMPLANNER_read}`,
      },
    },
    // scheduling > archive
    {
      path: "archive",
      name: "scheduling-archive",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-archive" */ "../../views/scheduling/SchedulingArchive.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_ARCHIVE_read}`,
      },
    },
    // scheduling > Config
    {
      path: "config",
      name: "scheduling-config",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/SchedulingConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: "",
      },
      children: [
        {
          path: "general",
          name: "scheduling-config-tab-general",
          component: () =>
            import(
              /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/ConfigTabGeneral.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${SCHEDULING_CONFIG_GENERAL_read}`,
          },
        },
        {
          path: "event-types",
          name: "scheduling-config-tab-event-types",
          component: () =>
            import(
              /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/ConfigTabEventTypes.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${SCHEDULING_CONFIG_EVENT_TYPES_read}`,
          },
        },
        {
          path: "templates",
          name: "scheduling-config-tab-templates",
          component: () =>
            import(
              /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/ConfigTabTemplates.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            permissionID: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_read}`,
          },
        },
      ],
    },
    {
      path: "config/event-types/new",
      name: "scheduling-config-tab-event-types-event-type-new",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/event-types/EventTypeNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
      },
    },
    {
      path: "config/event-types/:itemId",
      name: "scheduling-config-tab-event-types-event-type-details",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/event-types/EventTypeDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CONFIG_EVENT_TYPES_read}`,
      },
    },
    {
      path: "config/event-types/:itemId/edit",
      name: "scheduling-config-tab-event-types-event-type-edit",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/event-types/EventTypeEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CONFIG_EVENT_TYPES_update}`,
      },
    },
    {
      path: "config/templates/new",
      name: "scheduling-config-tab-templates-template-new",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/templates/TemplateNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_create}`,
      },
    },
    {
      path: "config/templates/:itemId",
      name: "scheduling-config-tab-templates-template-details",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/templates/TemplateDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_update}`,
      },
    },
    {
      path: "config/templates/:itemId/edit",
      name: "scheduling-config-tab-templates-template-edit",
      component: () =>
        import(
          /* webpackChunkName: "scheduling-config" */ "../../views/scheduling/config/templates/TemplateEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_update}`,
      },
    },
  ],
};
