import * as fb from "@/firebase";
// import { auth } from "../../firebase";
import router from "@/router/index";
// import { db } from "@/firebase";

import {
  CREATE_EVENT,
  CREATE_EVENTS,
  CREATE_EVENT_TYPE,
  CREATE_EVENT_TYPES,
  DELETE_EVENT,
  RESET_RESPONSES,
  DELETE_EVENT_TYPE,
  GET_EVENTS,
  GET_ARCHIVE,
  GET_EVENTS_STATUS,
  GET_CALENDAR_FEEDS,
  GET_EVENT_TYPES,
  CREATE_EVENT_TEMPLATE,
  UPDATE_EVENT_TEMPLATE,
  DELETE_EVENT_TEMPLATE,
  GET_EVENT_TEMPLATES,
  CREATE_EVENT_CANCELLATION_NOTICE,
  UPDATE_EVENT_RESPONSE,
  UPDATE_EVENT_RESPONSES,
  UPDATE_EVENT_META,
  UPDATE_REQUEST_META,
  UPDATE_EVENT,
  UPDATE_EVENT_TYPE,
  UPDATE_SCHEDULING_CONFIG,
  RESET_SCHEDULING,
} from "../action-types";
import {
  SET_EVENTS,
  SET_ARCHIVE,
  SET_CALENDAR_FEEDS,
  SET_EVENTS_ISO,
  SET_EVENTS_STATUS,
  SET_EVENT_TYPES,
  SET_EVENT_TEMPLATES,
  SET_LOADING,
} from "../mutation-types";

export default {
  namespaced: true,

  state: {
    events: [],
    archive: [],
    calendarFeeds: [],
    eventsISO: [],
    eventsStatus: [],
    eventTypes: [],
    eventTemplates: [],
  },

  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },

    [SET_EVENTS](state, payload) {
      state.events = payload;
    },

    [SET_CALENDAR_FEEDS](state, payload) {
      state.calendarFeeds = payload;
    },

    [SET_ARCHIVE](state, payload) {
      state.archive = payload;
    },

    [SET_EVENTS_STATUS](state, payload) {
      state.eventsStatus = payload;
    },

    [SET_EVENTS_ISO](state, payload) {
      state.eventsISO = payload;
    },

    [SET_EVENT_TYPES](state, payload) {
      state.eventTypes = payload;
    },

    [SET_EVENT_TEMPLATES](state, payload) {
      state.eventTemplates = payload;
    },
  },

  actions: {
    [RESET_SCHEDULING]({ commit }) {
      commit(SET_EVENTS, []);
      commit(SET_EVENTS_ISO, []);
      commit(SET_EVENT_TYPES, []);
      commit(SET_EVENT_TEMPLATES, []);
    },
    [GET_CALENDAR_FEEDS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("calendarFeeds")
        .where("uid", "==", fb.auth.currentUser.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_CALENDAR_FEEDS, data);
        })
        .catch((error) => console.log(error));
    },

    [GET_EVENTS]({ dispatch, commit }, payload) {
      const events = [];
      const dataISO = [];
      const dataID = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var limit = 60;
      var queryDate = new Date();
      // queryDate.setDate(queryDate.getDate() - 1); // 24h before now
      queryDate.setHours(queryDate.getHours() - 6); // 4h before now
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      var baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("events")
        .where("status", "==", "planned")
        .where(
          "duration.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(queryDate))
        );
      var reference = baseReference;
      if (payload.query.onlyOwn) {
        //queryIsFiltered is not available
        var ownUserGroup = payload.query.ownUserGroup; //rootState.organization.membership.group.id;
        var ownUid = fb.auth.currentUser.uid;

        reference = baseReference.where(
          "config.attendance.allowedAttendeesIds",
          "array-contains-any",
          ["all", ownUid, ownUserGroup]
        );
      }

      return reference
        .orderBy("duration.start.timestamp", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),

              // Overwrite
              duration: {
                inHours: doc.data().duration.inHours,
                inMinutes: doc.data().duration.inMinutes,
                start: {
                  weekday: doc.data().duration.start.weekday,
                  firebaseFormat: doc.data().duration.start.timestamp,
                  timestampToDate: doc.data().duration.start.timestamp.toDate(),
                  timestamp: doc
                    .data()
                    .duration.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.start.isOpen,
                },
                end: {
                  weekday: doc.data().duration.end.weekday,
                  firebaseFormat: doc.data().duration.end.timestamp,
                  timestampToDate: doc.data().duration.end.timestamp.toDate(),
                  timestamp: doc
                    .data()
                    .duration.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.end.isOpen,
                },
              },
            };
            events.push(object);

            const objectISO = {
              ...doc.data(),

              // Overwrite
              start: doc
                .data()
                .duration.start.timestamp.toDate()
                .toLocaleString("sv"), // locale 'sv' is Sweden, that will use ISO 8601 format
              end: doc
                .data()
                .duration.end.timestamp.toDate()
                .toLocaleString("sv"), // locale 'sv' is Sweden, that will use ISO 8601 format
            };
            dataISO.push(objectISO);

            dataID.push(doc.id);

            commit(SET_EVENTS, events);
            commit(SET_EVENTS_ISO, dataISO);
          });
          return events;
        })
        .then((result) => {
          if (payload.withStatus == true) {
            return dispatch(GET_EVENTS_STATUS, {
              organizationId: payload.organizationId,
              events: result,
            });
          }
        })
        .catch((error) => console.log(error));
    },
    [GET_ARCHIVE]({ commit }, payload) {
      const events = [];
      var options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var limit = 300;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("events")
        .where("status", "in", payload.query.status)
        // .where("type.id", "in", payload.query.types)
        // .where(
        //   "config.attendance.allowedAttendeesIds",
        //   "array-contains-any",
        //   payload.query.groups // Include events where only specific persons are invited
        // )
        .where(
          "duration.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(payload.query.range.start))
        )
        .where(
          "duration.start.timestamp",
          "<=",
          fb.Timestamp.fromDate(new Date(payload.query.range.end))
        )
        .orderBy("duration.start.timestamp", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              // overwrite
              duration: {
                start: {
                  firebaseFormat: doc.data().duration.start.timestamp,
                  dateFormat: doc.data().duration.start.timestamp.toDate(),
                  timestamp: doc
                    .data()
                    .duration.start.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.start.isOpen,
                },
                end: {
                  firebaseFormat: doc.data().duration.end.timestamp,
                  timestamp: doc
                    .data()
                    .duration.end.timestamp.toDate()
                    .toLocaleString("de-DE", options),
                  isOpen: doc.data().duration.end.isOpen,
                },
              },
            };
            events.push(object);

            return commit(SET_ARCHIVE, events);
          });
        })
        .catch((error) => console.log(error));
    },
    [GET_EVENTS_STATUS]({ commit }, payload) {
      var ids = payload.events.map((a) => a.meta.id);
      var userResponses = [];
      // Create an array of promises
      var promises = ids.map((item) => {
        return fb.organizationCollection
          .doc(payload.organizationId)
          .collection("events")
          .doc(item)
          .collection("responses")
          .doc(fb.auth.currentUser.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = {
                userResponse: {
                  ...doc.data(),
                  meta: {
                    eventId: item,
                    uid: doc.data().meta.uid,
                  },
                },
              };
              userResponses.push(data);
            } else {
              const data = {
                userResponse: {
                  status: {
                    id: "0",
                  },
                  guests: {
                    count: 0,
                  },
                  comment: "",
                  isAccepted: false,
                  isCanceled: false,
                  assignedTasks: [],
                  assignedPosition: {
                    custom: {
                      text: "",
                    },
                    comboId: "",
                    position: {
                      id: "",
                      shortTitle: "",
                    },
                    unit: {
                      id: "",
                      title: "",
                    },
                  },
                  type: "regular",
                  eventId: item,
                  meta: {
                    eventId: item,
                    uid: fb.auth.currentUser.uid,
                  },
                },
              };
              userResponses.push(data);
            }
          });
      });

      // Wait for all promises to resolve, then commit the mutation
      Promise.all(promises)
        .then(() => {
          commit(SET_EVENTS_STATUS, userResponses);
        })
        .catch((error) => console.log(error));
    },
    [CREATE_EVENT]({ commit }, payload) {
      commit(SET_LOADING, true);
      var customResponseDeadlineTimestamp = payload.duration.start.timestamp;
      if (payload.config.responses.deadline.isCustom) {
        customResponseDeadlineTimestamp =
          payload.config.responses.deadline.timestamp;
      }

      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("events")
        .doc();
      docRef
        .set({
          title: payload.title,
          description: payload.description,
          type: {
            id: payload.type.id,
            title: payload.type.title,
            shortTitle: payload.type.shortTitle,
            color: payload.type.color,
            description: payload.type.description,
          },
          location: {
            name: payload.location.name,
          },
          requiredPPE: payload.requiredPPE,
          tasks: payload.tasks,
          units: payload.units,
          duration: {
            start: {
              weekday: getISODay(payload.duration.start.timestamp),
              timestamp: payload.duration.start.timestamp,
              isOpen: payload.duration.start.isOpen,
            },
            end: {
              weekday: getISODay(payload.duration.end.timestamp),
              timestamp: payload.duration.end.timestamp,
              isOpen: payload.duration.end.isOpen,
            },
            inHours: calculateDuration(
              payload.duration.start.timestamp,
              payload.duration.end.timestamp,
              "hours"
            ),
            inMinutes: calculateDuration(
              payload.duration.start.timestamp,
              payload.duration.end.timestamp,
              "minutes"
            ),
          },
          details: payload.details,
          config: {
            organizer: {
              isAppointed: payload.config.organizer.isAppointed,
              hasAdditionalPermissions:
                payload.config.organizer.hasAdditionalPermissions,
              description: payload.config.organizer.description,
              personIds: payload.config.organizer.personIds,
              personData: payload.config.organizer.personData,
            },
            attendance: {
              isMandatory: payload.config.attendance.isMandatory,
              isLimited: payload.config.attendance.isLimited,
              description: payload.config.attendance.description,
              minAttendees: payload.config.attendance.minAttendees,
              maxAttendees: payload.config.attendance.maxAttendees,
              allowedAttendeesIds:
                payload.config.attendance.allowedAttendeesIds,
              allowedPersons: payload.config.attendance.allowedPersons,
              allowedUserGroups: payload.config.attendance.allowedUserGroups,
            },
            responses: {
              areBinding: payload.config.responses.areBinding,
              areLocked: payload.config.responses.areLocked,
              deadline: {
                isCustom: payload.config.responses.deadline.isCustom,
                timestamp: customResponseDeadlineTimestamp,
              },
            },
          },
          count: {
            totalResponses: 0,
            totalConfirmations: 0,
            totalCancellations: 0,
            totalIfNecessary: 0,
            totalAway: 0,
            totalGuests: 0,
          },
          status: "planned", // 'draft', 'planned', 'live', 'finished', 'canceled',
          statusAnalytics: { id: null, reason: null },
          internalNote: null,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "scheduling-events",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_EVENTS]({ commit }, payload) {
      commit(SET_LOADING, true);

      var batch = fb.db.batch();

      for (let i = 0; i < payload.events.length; i++) {
        const docRef = fb.organizationCollection
          .doc(payload.organizationId)
          .collection("events")
          .doc();

        batch.set(docRef, {
          ...payload.events[i],
          status: "planned", // 'draft', 'planned', 'live', 'finished', 'canceled'
          statusAnalytics: { id: null, reason: null },
          internalNote: null,
          organizationId: payload.organizationId,
          count: {
            totalResponses: 0,
            totalConfirmations: 0,
            totalCancellations: 0,
            totalIfNecessary: 0,
            totalAway: 0,
            totalGuests: 0,
          },
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        });
      }
      batch
        .commit()
        .then(function () {
          router.push({
            name: "scheduling-events",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_EVENT]({ commit }, payload) {
      commit(SET_LOADING, true);
      var customResponseDeadlineTimestamp = payload.duration.start.timestamp;
      if (payload.config.responses.deadline.isCustom) {
        customResponseDeadlineTimestamp =
          payload.config.responses.deadline.timestamp;
      }
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("events")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          description: payload.description,
          "type.id": payload.type.id,
          "type.title": payload.type.title,
          "type.shortTitle": payload.type.shortTitle,
          "type.color": payload.type.color,
          "type.description": payload.type.description,
          "location.name": payload.location.name,
          requiredPPE: payload.requiredPPE,
          tasks: payload.tasks,
          units: payload.units,
          "duration.start.weekday": getISODay(payload.duration.start.timestamp),
          "duration.start.timestamp": payload.duration.start.timestamp,
          "duration.start.isOpen": payload.duration.start.isOpen,
          "duration.end.weekday": getISODay(payload.duration.end.timestamp),
          "duration.end.timestamp": payload.duration.end.timestamp,
          "duration.end.isOpen": payload.duration.end.isOpen,
          details: payload.details,
          "duration.inHours": calculateDuration(
            payload.duration.start.timestamp,
            payload.duration.end.timestamp,
            "hours"
          ),
          "duration.inMinutes": calculateDuration(
            payload.duration.start.timestamp,
            payload.duration.end.timestamp,
            "minutes"
          ),

          "config.organizer.isAppointed": payload.config.organizer.isAppointed,
          "config.organizer.hasAdditionalPermissions":
            payload.config.organizer.hasAdditionalPermissions,
          "config.organizer.description": payload.config.organizer.description,
          "config.organizer.personIds": payload.config.organizer.personIds,
          "config.organizer.personData": payload.config.organizer.personData,

          "config.attendance.isMandatory":
            payload.config.attendance.isMandatory,
          "config.attendance.isLimited": payload.config.attendance.isLimited,
          "config.attendance.description":
            payload.config.attendance.description,
          "config.attendance.minAttendees":
            payload.config.attendance.minAttendees,
          "config.attendance.maxAttendees":
            payload.config.attendance.maxAttendees,
          "config.attendance.allowedAttendeesIds":
            payload.config.attendance.allowedAttendeesIds,
          "config.attendance.allowedPersons":
            payload.config.attendance.allowedPersons,
          "config.attendance.allowedUserGroups":
            payload.config.attendance.allowedUserGroups,

          "config.responses.areBinding": payload.config.responses.areBinding,
          "config.responses.areLocked": payload.config.responses.areLocked,
          "config.responses.deadline.isCustom":
            payload.config.responses.deadline.isCustom,
          "config.responses.deadline.timestamp":
            customResponseDeadlineTimestamp,

          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "scheduling-events",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [RESET_RESPONSES]({ commit }, payload) {
      commit(SET_LOADING, true);
      const callFunction = fb.functions.httpsCallable(
        "scheduling-resetResponses"
      );
      callFunction({
        organizationId: payload.organizationId,
        eventId: payload.itemId,
      }).then((result) => {
        commit(SET_LOADING, false);
        return result;
      });
    },
    [DELETE_EVENT]({ commit }, payload) {
      commit(SET_LOADING, true);
      const callFunction = fb.functions.httpsCallable("scheduling-deleteEvent");
      const organizationId = payload.organizationId;
      const eventId = payload.itemId;
      console.log(organizationId, eventId);
      callFunction({
        organizationId: organizationId,
        eventId: eventId,
      });

      commit(SET_LOADING, false);
    },
    [CREATE_EVENT_CANCELLATION_NOTICE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("events")
        .doc(payload.itemId)
        .collection("cancellations")
        .doc(payload.user.uid);

      docRef
        .set({
          status: "submitted",
          reason: "0", // 0 = "no reason given"
          notification: {
            message: payload.notification.message,
            emailNotificationTaskIds: [],
          },
          user: {
            displayName: payload.user.displayName,
            uid: payload.user.uid,
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_EVENT_RESPONSE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("events")
        .doc(payload.itemId)
        .collection("responses")
        .doc(payload.uid);

      const reversesAcceptanceDueStatusConflict = (
        previousData,
        currentData
      ) => {
        return (
          previousData.isAccepted &&
          currentData.isAccepted === false &&
          (currentData.status.id === "2" || currentData.status.id === "4")
        );
      };

      fb.db
        .runTransaction((transaction) => {
          return transaction.get(docRef).then((doc) => {
            if (doc.exists) {
              var previousData = doc.data();
              var reverseAcceptanceChangeDetected =
                reversesAcceptanceDueStatusConflict(previousData, payload);
            }

            transaction.set(
              docRef,
              {
                organizationId: payload.organizationId,
                eventId: payload.itemId,
                type: "regular", // regular, copiedFromRequest, automatedFromLeave
                status: {
                  id: payload.status.id,
                },
                isAccepted: payload.isAccepted || false,
                isCanceled: payload.isCanceled || false,
                assignedTasks: payload.assignedTasks || [],
                assignedPosition: {
                  custom: {
                    text: payload.assignedPosition.custom.text || "",
                  },
                  unit: {
                    id: payload.assignedPosition.unit.id,
                    title: payload.assignedPosition.unit.title,
                  },
                  position: {
                    id: payload.assignedPosition.position.id,
                    shortTitle: payload.assignedPosition.position.shortTitle,
                  },
                  comboId: payload.assignedPosition.comboId,
                },
                guests: {
                  count: payload.guests.count,
                },
                comment: payload.comment,
                meta: {
                  uid: payload.uid,
                  lastUpdatedAt: fb.FieldValue.serverTimestamp(),
                  lastUpdatedBy: fb.auth.currentUser.uid,
                  lastAcceptanceChangeAt: reverseAcceptanceChangeDetected
                    ? null
                    : previousData
                    ? previousData.meta.lastAcceptanceChangeAt || null
                    : null,
                  lastAcceptanceChangeBy: reverseAcceptanceChangeDetected
                    ? null
                    : previousData
                    ? previousData.meta.lastAcceptanceChangeBy || null
                    : null,
                },
              },
              { merge: true }
            );
          });
        })
        .then(() => {
          // router.push({
          //   name: "scheduling-event-details-tab-general",
          //   params: { organizationId: payload.organizationId, itemId: payload.itemId },
          // });
        })
        .catch((error) => console.log(error));

      commit(SET_LOADING, false);
    },

    [UPDATE_EVENT_RESPONSES]({ commit }, payload) {
      // Start loading state
      commit(SET_LOADING, true);

      // Begin database transaction
      return fb.db
        .runTransaction((transaction) => {
          // Array to hold all promises for the transaction
          const promises = [];

          // Iterate over all responses in the payload
          for (let i = 0; i < payload.responses.length; i++) {
            // Get reference to the document in the database
            const docRef = fb.organizationCollection
              .doc(payload.organizationId)
              .collection("events")
              .doc(payload.itemId)
              .collection("responses")
              .doc(payload.responses[i].meta.uid);

            // Function to check if any acceptance conditions have changed
            const checkAcceptanceConditions = (previousData, currentData) => {
              return [
                previousData.isAccepted !== currentData.isAccepted,
                previousData.assignedPosition.custom.text !==
                  currentData.assignedPosition.custom.text,
                previousData.assignedPosition.unit.id !==
                  currentData.assignedPosition.unit.id,
                previousData.assignedPosition.position.id !==
                  currentData.assignedPosition.position.id,
                previousData.assignedPosition.comboId !==
                  currentData.assignedPosition.comboId,
              ].some((condition) => condition);
            };

            // Function to check if any other conditions have changed
            const checkOtherConditions = (previousData, currentData) => {
              return [
                previousData.status.id !== currentData.status.id,
                previousData.comment !== currentData.comment,
                previousData.guests.count !== currentData.guests.count,
                previousData.assignedTasks.length !==
                  currentData.assignedTasks.length,
              ].some((condition) => condition);
            };

            // Add promise to the array
            promises.push(
              transaction.get(docRef).then(() => {
                const response = payload.responses[i];
                const acceptanceChangeDetected = checkAcceptanceConditions(
                  response.previousData,
                  response
                );
                console.log(acceptanceChangeDetected);
                const otherChangeDetected = checkOtherConditions(
                  response.previousData,
                  response
                );

                // Update the document in the database
                transaction.set(
                  docRef,
                  {
                    type: "regular",
                    eventId: payload.itemId,
                    organizationId: payload.organizationId,
                    status: { id: response.status.id },
                    isAccepted: response.isAccepted,
                    isCanceled: response.isCanceled || false,
                    assignedTasks: response.assignedTasks,
                    assignedPosition: {
                      custom: {
                        text: response.assignedPosition.custom.text,
                      },
                      unit: {
                        id: response.assignedPosition.unit.id,
                        title: response.assignedPosition.unit.title,
                      },
                      position: {
                        id: response.assignedPosition.position.id,
                        shortTitle:
                          response.assignedPosition.position.shortTitle,
                      },
                      comboId: response.assignedPosition.comboId,
                    },
                    guests: { count: response.guests.count },
                    comment: response.comment,
                    meta: {
                      uid: response.meta.uid,
                      lastAcceptanceChangeAt: acceptanceChangeDetected
                        ? fb.FieldValue.serverTimestamp()
                        : response.meta.lastAcceptanceChangeAt || null,
                      lastAcceptanceChangeBy: acceptanceChangeDetected
                        ? fb.auth.currentUser.uid
                        : response.meta.lastAcceptanceChangeBy || null,
                      lastUpdatedAt: otherChangeDetected
                        ? fb.FieldValue.serverTimestamp()
                        : response.meta.lastUpdatedAt,
                      lastUpdatedBy: otherChangeDetected
                        ? fb.auth.currentUser.uid
                        : response.meta.lastUpdatedBy,
                    },
                  },
                  { merge: true }
                );
              })
            );
          }

          // Wait for all promises to resolve
          return Promise.all(promises);
        })
        .then(() => {
          // Log success message
          console.log("Transaction successfully committed!");
        })
        .then(function () {
          // End loading state
          commit(SET_LOADING, false);
          // Redirect to the responses tab of the event details
          router.push({
            name: "scheduling-event-details-tab-responses",
            params: {
              organizationId: payload.organizationId,
              itemId: payload.itemId,
            },
          });
        })
        .catch((error) => {
          // Log error message
          console.log("Transaction failed: ", error);
          throw error;
        });
    },

    [UPDATE_EVENT_META]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("events")
        .doc(payload.itemId)
        .set(
          {
            status: payload.status,
            statusAnalytics: {
              id: payload.statusAnalytics.id,
              reason: payload.statusAnalytics.reason,
            },
            internalNote: payload.internalNote,
            meta: {
              lastUpdatedAt: fb.FieldValue.serverTimestamp(),
              lastUpdatedBy: fb.auth.currentUser.uid,
            },
          },
          { merge: true }
        )
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_REQUEST_META]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("requests")
        .doc(payload.itemId)
        .set(
          {
            status: payload.status,
            statusAnalytics: {
              id: payload.statusAnalytics.id,
              reason: payload.statusAnalytics.reason,
            },
            internalNote: payload.internalNote,
            meta: {
              lastUpdatedAt: fb.FieldValue.serverTimestamp(),
              lastUpdatedBy: fb.auth.currentUser.uid,
            },
          },
          { merge: true }
        )
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [GET_EVENT_TYPES]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = [];
        fb.organizationCollection
          .doc(payload.organizationId)
          .collection("eventTypes")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              data.push(doc.data());
            });
            data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
            commit(SET_EVENT_TYPES, data);
            resolve(querySnapshot);
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              console.log("Error getting document:", error);
              reject(error);
            }
          );
      });
    },
    [CREATE_EVENT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("eventTypes")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          color: payload.color,
          sortKey: payload.sortKey,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "scheduling-config-tab-event-types",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_EVENT_TYPES]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("eventTypes");

      payload.eventTypes.forEach(function (eventType) {
        const newDocRef = batchRef.doc();
        const object = {
          ...eventType,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_EVENT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("eventTypes")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          color: payload.color,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "scheduling-config-tab-event-types",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_EVENT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("eventTypes")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "scheduling-config-tab-event-types",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [GET_EVENT_TEMPLATES]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("eventTemplates")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          data.sort(
            (a, b) =>
              parseFloat(a.template.sortKey) - parseFloat(b.template.sortKey)
          );
          commit(SET_EVENT_TEMPLATES, data);
        })
        .catch((error) => console.log(error), false);
    },
    [CREATE_EVENT_TEMPLATE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("eventTemplates")
        .doc();
      docRef
        .set({
          template: {
            title: payload.template.title,
            description: payload.template.description,
            sortKey: payload.template.sortKey,
          },
          data: {
            title: payload.data.title,
            description: payload.data.description,
            type: {
              id: payload.data.type.id,
              title: payload.data.type.title,
              shortTitle: payload.data.type.shortTitle,
              color: payload.data.type.color,
              description: payload.data.type.description,
            },
            location: {
              name: payload.data.location.name,
            },
            requiredPPE: payload.data.requiredPPE,
            tasks: payload.data.tasks,
            units: payload.data.units,
            duration: {
              start: {
                time: payload.data.duration.start.time,
                isOpen: payload.data.duration.start.isOpen,
              },
              end: {
                time: payload.data.duration.end.time,
                isOpen: payload.data.duration.end.isOpen,
              },
            },
            details: payload.data.details,
            config: {
              organizer: {
                isAppointed: payload.data.config.organizer.isAppointed,
                hasAdditionalPermissions:
                  payload.data.config.organizer.hasAdditionalPermissions,
                description: payload.data.config.organizer.description,
                personIds: payload.data.config.organizer.personIds,
                personData: payload.data.config.organizer.personIds,
              },
              attendance: {
                isMandatory: payload.data.config.attendance.isMandatory,
                isLimited: payload.data.config.attendance.isLimited,
                description: payload.data.config.attendance.description,
                minAttendees: payload.data.config.attendance.minAttendees,
                maxAttendees: payload.data.config.attendance.maxAttendees,
                allowedAttendeesIds:
                  payload.data.config.attendance.allowedAttendeesIds,
                allowedPersons: payload.data.config.attendance.allowedPersons,
                allowedUserGroups:
                  payload.data.config.attendance.allowedUserGroups,
              },
              responses: {
                areBinding: payload.data.config.responses.areBinding,
                areLocked: payload.data.config.responses.areLocked,
                deadline: {
                  isCustom: payload.data.config.responses.deadline.isCustom,
                  inMinutes: payload.data.config.responses.deadline.inMinutes,
                },
              },
            },
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "scheduling-config-tab-templates",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_EVENT_TEMPLATE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("eventTemplates")
        .doc(payload.itemId);
      docRef
        .update({
          "template.title": payload.template.title,
          "template.description": payload.template.description,
          "template.sortKey": payload.template.sortKey,

          "data.title": payload.data.title,
          "data.description": payload.data.description,
          "data.type.id": payload.data.type.id,
          "data.type.title": payload.data.type.title,
          "data.type.shortTitle": payload.data.type.shortTitle,
          "data.type.color": payload.data.type.color,
          "data.type.description": payload.data.type.description,
          "data.location.name": payload.data.location.name,
          "data.requiredPPE": payload.data.requiredPPE,
          "data.tasks": payload.data.tasks,
          "data.units": payload.data.units,
          "data.duration.start.time": payload.data.duration.start.time,
          "data.duration.start.isOpen": payload.data.duration.start.isOpen,
          "data.duration.end.time": payload.data.duration.end.time,
          "data.duration.end.isOpen": payload.data.duration.end.isOpen,

          "data.details": payload.data.details,

          "data.config.organizer.isAppointed":
            payload.data.config.organizer.isAppointed,
          "data.config.organizer.hasAdditionalPermissions":
            payload.data.config.organizer.hasAdditionalPermissions,
          "data.config.organizer.description":
            payload.data.config.organizer.description,
          "data.config.organizer.personIds":
            payload.data.config.organizer.personIds,
          "data.config.organizer.personData":
            payload.data.config.organizer.personData,

          "data.config.attendance.isMandatory":
            payload.data.config.attendance.isMandatory,
          "data.config.attendance.isLimited":
            payload.data.config.attendance.isLimited,
          "data.config.attendance.description":
            payload.data.config.attendance.description,
          "data.config.attendance.minAttendees":
            payload.data.config.attendance.minAttendees,
          "data.config.attendance.maxAttendees":
            payload.data.config.attendance.maxAttendees,
          "data.config.attendance.allowedAttendeesIds":
            payload.data.config.attendance.allowedAttendeesIds,
          "data.config.attendance.allowedPersons":
            payload.data.config.attendance.allowedPersons,
          "data.config.attendance.allowedUserGroups":
            payload.data.config.attendance.allowedUserGroups,

          "data.config.responses.areBinding":
            payload.data.config.responses.areBinding,
          "data.config.responses.areLocked":
            payload.data.config.responses.areLocked,
          "data.config.responses.deadline.isCustom":
            payload.data.config.responses.deadline.isCustom,
          "data.config.responses.deadline.inMinutes":
            payload.data.config.responses.deadline.inMinutes,

          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "scheduling-config-tab-templates",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_EVENT_TEMPLATE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("eventTemplates")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "scheduling-config-tab-templates",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_SCHEDULING_CONFIG]({ commit }, payload) {
      commit(SET_LOADING, true);
      let updateData = {};
      if (payload.type === "onlyCancellationConfig") {
        updateData = {
          "config.scheduling.cancellation.allowNotice":
            payload.cancellation.allowNotice,
          "config.scheduling.cancellation.notifyPersons":
            payload.cancellation.notifyPersons,
          "config.scheduling.cancellation.notifyUserGroups":
            payload.cancellation.notifyUserGroups,
        };
      } else if (payload.type === "onlyGeneralConfig") {
        updateData = {
          "config.scheduling.defaultDeadlineInMinutes":
            payload.defaultDeadlineInMinutes,
          "config.scheduling.showAllEventsInList": payload.showAllEventsInList,
        };
      } else if (payload.type === "onlyTemplateConfig") {
        updateData = {
          "config.scheduling.ppeTemplates": payload.ppeTemplates,
          "config.scheduling.taskTemplates": payload.taskTemplates,
        };
      }
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          ...updateData,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
  },

  getters: {},
};

function calculateDuration(startTimestamp, endTimestamp, unit = "minutes") {
  const startTime = startTimestamp.toDate();
  const endTime = endTimestamp.toDate();

  const durationInMilliseconds = endTime - startTime;

  if (unit === "minutes") {
    // Calculate and return duration in minutes
    return Math.floor(durationInMilliseconds / (60 * 1000));
  } else if (unit === "hours") {
    // Calculate and return duration in hours
    return durationInMilliseconds / (60 * 60 * 1000);
  } else {
    // Invalid unit specified
    return null;
  }
}

function getISODay(timestamp) {
  // Ensure timestamp is a valid Firebase Timestamp
  if (!timestamp || !timestamp.toDate) {
    return null;
  }

  // Get a JavaScript Date object from the Firebase Timestamp
  const date = timestamp.toDate();

  // Get the ISO day (1 for Monday, 7 for Sunday)
  let isoDay = date.getUTCDay();

  // Adjust Sunday (0) to 7 to match ISO 8601 standard
  if (isoDay === 0) {
    isoDay = 7;
  }

  return isoDay;
}
